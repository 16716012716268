.header {
  background-color: var(--mantine-color-body);
  box-shadow: 0px 1px 11px 0px rgb(0 0 0/15%);
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.link {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-md);
  font-weight: 700;
}

.inputSearch input:focus {
    border-color: #ddd !important
}

