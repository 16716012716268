.card {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
}

.card:hover {
    border-color: #d84a2f;
}

.section {
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    padding-bottom: var(--mantine-spacing-sm);
}

.firstSection::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.link {
    transition: all .3s;
}

.link:hover {
    color: #d84a2f !important;
}

.label {
    text-transform: uppercase;
    font-size: var(--mantine-font-size-xs);
    font-weight: 700;
}

.badge {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.card:hover a[id=btn] {
    border-color: #d84a2f !important;
    background-color: #d84a2f !important;
    color: #fff !important;
}


.boxProductCart {
    direction: rtl;
    margin-top: 20px;
    border: 1px solid #dedede;
    border-radius: 3px;
    padding: 10px 20px;
    box-shadow: 0px 1px 10px -5px #dedede;
}

.boxProductCart:first-child {
    margin-top: 0px;
}